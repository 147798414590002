<template>
  <div>
    <component :is="data.type" :data="data" :services="services" :callback="function(data){callback(data)}" />
  </div>
</template>
<script>
export default {
  components: {
    eventpicker () { return import('@/components/assistent/components/eventpicker/appointments') },
    datepicker () { return import('@/components/assistent/components/datepicker/appointments') }
  },
  props: { data: Object, services: Array, callback: Function }
}
</script>
